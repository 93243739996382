import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Grid, Box, Typography, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import * as Yup from 'yup';

import BaseButton from 'components/formControl/baseButton/BaseButton';
import GoogleSign from 'components/GoogleSign';
import AppleSign from 'components/AppleSign';
import BaseTextField from 'components/formControl/baseTextField/BaseTextField';
import ErrorNotification from 'components/ErrorNotification';
import PageLoading from 'components/PageLoading';
import { ReactComponent as IconAtSign } from 'assets/icons/Icon-At-Sign.svg';
import { ReactComponent as IconCheckmark } from 'assets/icons/Icon-Checkmark.svg';
import { ReactComponent as IconLock } from 'assets/icons/Icon-Lock.svg';

import { setStateValue } from 'store/reducers/user';
import { resendConfirmationCode, signIn } from 'store/actions/user';

import palette from 'theme/palette';
import styles from './SignIn.module.scss';

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { email, loading, error, errorCode } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(setStateValue({ type: 'error', data: '' }));
  }, []);

  useEffect(() => {
    if (errorCode === 'UserNotConfirmedException') {
      dispatch(resendConfirmationCode(email));
      navigate('/verification');
    }
  }, [errorCode]);

  const federatedSignIn = (provider) => {
    Auth.federatedSignIn({ provider }).catch((e) => console.log(e));
  };

  const initialState = { email: '', password: '' };

  const SigninSchema = Yup.object().shape({
    password: Yup.string().required(t('validation.required')),
    email: Yup.string().email(t('validation.email')).required(t('validation.required'))
  });

  const handleSubmit = (values) => {
    dispatch(signIn(values));
    dispatch(setStateValue({ type: 'tempPassword', data: values.password }));
  };

  return (
    <div className={styles.container}>
      <Formik initialValues={initialState} validationSchema={SigninSchema} onSubmit={handleSubmit}>
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box
              className={styles.formContainer}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <Typography
                sx={{ marginBottom: '16px' }}
                component="h2"
                variant="h3"
                color={palette.variables.darkPurple}>
                {t('logIn')}
              </Typography>

              <Typography
                sx={{ marginBottom: '15px' }}
                component="h3"
                variant="body2"
                color={palette.white['400']}>
                {t('welcomeBack')}
              </Typography>

              {error ? (
                <ErrorNotification sx={{ marginBottom: '19px' }} errorText={error} />
              ) : (
                <div />
              )}

              <Box component="div">
                <BaseTextField
                  InputLabelProps={{ required: false }}
                  sx={{ marginBottom: '24px' }}
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  value={values.email}
                  color={!errors.email && values.email ? 'success' : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconAtSign />
                      </InputAdornment>
                    ),
                    endAdornment:
                      !errors.email && values.email ? (
                        <InputAdornment position="end">
                          <IconCheckmark />
                        </InputAdornment>
                      ) : (
                        <div />
                      )
                  }}
                />

                <BaseTextField
                  InputLabelProps={{ required: false }}
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  error={!!errors.password}
                  helperText={errors.password}
                  onChange={handleChange}
                  value={values.password}
                  color={!errors.password && values.password ? 'success' : ''}
                  sx={{ marginBottom: '30px' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconLock />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <BaseButton
                        variant="text"
                        onClick={() => navigate('/forgot-password/input')}
                        element={
                          <Typography
                            variant="caption"
                            align="center"
                            display="block"
                            color={palette.variables.green}
                            gutterBottom>
                            {t('forgot')}?
                          </Typography>
                        }
                      />
                    )
                  }}
                />

                <BaseButton
                  customColor={palette.variables.darkPurple}
                  type="submit"
                  fullWidth
                  variant="contained"
                  element={t('logIn')}
                  sx={{ marginBottom: '32px' }}
                />

                <Typography
                  sx={{ marginBottom: '24px' }}
                  component="h3"
                  variant="body2"
                  align="center"
                  color={palette.white['400']}>
                  {t('orLoginWith')}
                </Typography>

                <Grid container justifyContent="center" spacing={3} sx={{ marginBottom: '50px' }}>
                  <Grid item>
                    <AppleSign
                      onClick={() => federatedSignIn(CognitoHostedUIIdentityProvider.Apple)}
                    />
                  </Grid>
                  <Grid item>
                    <GoogleSign
                      onClick={() => federatedSignIn(CognitoHostedUIIdentityProvider.Google)}
                    />
                  </Grid>
                </Grid>

                <Grid container alignItems="center" justifyContent="center">
                  <Grid item alignItems="center">
                    <Typography
                      sx={{ marginBottom: 0, marginRight: '5px' }}
                      color={palette.white['400']}
                      variant="caption"
                      align="center"
                      display="block"
                      gutterBottom>
                      {t('isNew')}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Link
                      onClick={() => navigate('/signUp')}
                      variant="body2"
                      color={palette.variables.green}>
                      {t('register')}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        )}
      </Formik>

      <PageLoading loading={loading} />
    </div>
  );
}
