import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import StatusOfRequest from 'layouts/statusOfRequest/StatusOfRequest';
import { ReactComponent as IllustrationDone } from 'assets/images/illustrations/Illustration_done.svg';
import { signIn } from 'store/actions/user';

export default function ChangePasswordSuccess() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user, email, tempPassword } = useSelector((state) => state.user);

  return (
    <StatusOfRequest
      title={t('congratulations')}
      subTitle={t('emailVerified')}
      image={<IllustrationDone style={{ marginBottom: '24px' }} />}
      btnText={t('done')}
      onClick={() => {
        dispatch(signIn({ email: email || user?.username, password: tempPassword }));
        navigate('/build-your-profile/step-1');
      }}
    />
  );
}
