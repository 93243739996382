import { createSlice } from '@reduxjs/toolkit';
import { getPartnerById, getPartners } from 'store/actions/partners';
import { partnersList } from 'utils/fakeValues';

const initialState = {
  partners: partnersList || [],
  partner: null,
  selectedPartner: null,
  isLoading: false,
  error: null
};

export const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setStatePartnerValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data
      };
    }
  },
  extraReducers: {
    [getPartners.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getPartners.fulfilled]: (state, { payload }) => {
      state.partners = payload;
      state.isLoading = false;
    },
    [getPartners.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [getPartnerById.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getPartnerById.fulfilled]: (state, { payload }) => {
      state.partner = payload;
      state.isLoading = false;
    },
    [getPartnerById.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    }
  }
});

export const { setStatePartnerValue } = partnersSlice.actions;

export default partnersSlice.reducer;
