import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';

import BaseTextField from 'components/formControl/baseTextField/BaseTextField';
import BaseSelect from 'components/formControl/baseSelect/BaseSelect';
import BaseButton from 'components/formControl/baseButton/BaseButton';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { fakeOptionsList } from 'utils/fakeValues';

import palette from 'theme/palette';
import styles from '../admin.module.scss';

const FilterDetailsPage = () => {
  const { t } = useTranslation();

  const initialState = {
    productCategory: '',
    questionType: '',
    selections: [{ id: 0, title: '' }],
    productCategory2: '',
    questionType2: '',
    minimumValue: '',
    maximumValue: '',
    tags: [{ id: 0, title: '' }],
    productCategory3: '',
    questionType3: '',
    selectionTags: [{ id: 0, title: '' }],
    productCategory4: '',
    questionType4: '',
    checkboxName: '',
    tag: ''
  };

  const ProductCreateSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    imageFull: Yup.string().required(t('validation.required'))
  });

  const handleSubmit = (values) => {
    console.log('values___', values);
    // dispatch(setStatePartnerValue({ type: 'partner', data: values }));
    // navigate('/admin/congrats');
  };

  return (
    <Box className={styles.cardProduct}>
      <Formik
        initialValues={initialState}
        validationSchema={ProductCreateSchema}
        onSubmit={handleSubmit}>
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="bodyBig" component="p" color="common.darkPurple" my={3}>
              {t('admin.addFilter')}
            </Typography>
            <BaseSelect
              name="productCategory"
              label="Product Category"
              items={fakeOptionsList}
              initvalue={1}
              sx={{ width: '100%', marginBottom: 0 }}
              onChange={(value) => setFieldValue('productCategory', value)}
            />

            <Typography variant="bodyBig" component="p" my={2}>
              {t('admin.question')} 1
            </Typography>

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="productCategory"
              label="Filter Question"
              name="filterQuestion"
              onChange={handleChange}
              error={!!errors.filterQuestion}
              helperText={errors.filterQuestion}
              value={''}
              color={!errors.filterQuestion && values.filterQuestion ? 'success' : ''}
            />

            <BaseSelect
              name="questionType"
              label="Question Type"
              items={fakeOptionsList}
              initvalue={values.questionType}
              sx={{ width: '100%', marginBottom: 0 }}
              onChange={(value) => setFieldValue('questionType', value)}
            />

            <FieldArray
              name="selections"
              render={(arrayHelpers) => (
                <div style={{ width: '100%' }}>
                  {values.selections.map((item, index) => {
                    return (
                      <Grid container key={index} alignItems="center" mt={3}>
                        <Grid item xs={10}>
                          <BaseTextField
                            key={index}
                            InputLabelProps={{ required: false }}
                            sx={{ marginBottom: '24px' }}
                            margin="normal"
                            fullWidth
                            id="selection"
                            label={`Selection ${index + 1}`}
                            name={`selections[${index}].title`}
                            onChange={handleChange}
                            error={!!errors.selections}
                            helperText={errors.selections}
                            value={''}
                            color={!errors.selections && values.selections ? 'success' : ''}
                          />
                        </Grid>
                        <Grid item xs={2} pl={2}>
                          <Box
                            style={{
                              width: '70px',
                              display: 'flex',
                              justifyContent: 'right'
                            }}>
                            {index > 0 && (
                              <button
                                type="button"
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                                style={{
                                  border: 0,
                                  backgroundColor: '#fff',
                                  borderRadius: '20px'
                                }}>
                                <RemoveCircleOutlineOutlinedIcon sx={{ color: 'common.error' }} />
                              </button>
                            )}
                            <button
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  id: '',
                                  title: ''
                                })
                              }
                              style={{
                                border: 0,
                                backgroundColor: '#fff',
                                borderRadius: '20px'
                              }}>
                              <AddCircleOutlineOutlinedIcon sx={{ color: 'common.green' }} />
                            </button>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              )}
            />

            <Typography variant="bodyBig" component="p" my={2}>
              {t('admin.question')} 2
            </Typography>

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="productCategory2"
              label="Filter Question"
              name="filterQuestion2"
              onChange={handleChange}
              error={!!errors.filterQuestion2}
              helperText={errors.filterQuestion2}
              value={''}
              color={!errors.filterQuestion2 && values.filterQuestion2 ? 'success' : ''}
            />

            <BaseSelect
              name="questionType2"
              label="Question Type"
              items={fakeOptionsList}
              initvalue={values.questionType2}
              sx={{ width: '100%', marginBottom: 0 }}
              onChange={(value) => setFieldValue('questionType2', value)}
            />

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="minimumValue"
              label="Minimum value"
              name="minimumValue"
              onChange={handleChange}
              error={!!errors.minimumValue}
              helperText={errors.minimumValue}
              value={''}
              color={!errors.minimumValue && values.minimumValue ? 'success' : ''}
            />

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="maximumValue"
              label="Maximum value"
              name="maximumValue"
              onChange={handleChange}
              error={!!errors.maximumValue}
              helperText={errors.maximumValue}
              value={''}
              color={!errors.maximumValue && values.maximumValue ? 'success' : ''}
            />

            <FieldArray
              name="tags"
              render={(arrayHelpers) => (
                <div style={{ width: '100%' }}>
                  {values.tags.map((item, index) => {
                    return (
                      <Grid container key={index} alignItems="center" mt={3}>
                        <Grid item xs={10}>
                          <BaseSelect
                            name={`tags[${index}].title`}
                            label={`Tag ${index + 1}`}
                            items={fakeOptionsList}
                            // initvalue={}
                            sx={{ width: '100%', marginBottom: 0 }}
                            onChange={(value) => setFieldValue(`tags[${index}].title`, value)}
                          />
                        </Grid>
                        <Grid item xs={2} pl={2}>
                          <Box
                            style={{
                              width: '70px',
                              display: 'flex',
                              justifyContent: 'right'
                            }}>
                            {index > 0 && (
                              <button
                                type="button"
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                                style={{
                                  border: 0,
                                  backgroundColor: '#fff',
                                  borderRadius: '20px'
                                }}>
                                <RemoveCircleOutlineOutlinedIcon sx={{ color: 'common.error' }} />
                              </button>
                            )}
                            <button
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  id: '',
                                  title: ''
                                })
                              }
                              style={{
                                border: 0,
                                backgroundColor: '#fff',
                                borderRadius: '20px'
                              }}>
                              <AddCircleOutlineOutlinedIcon sx={{ color: 'common.green' }} />
                            </button>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              )}
            />

            <Typography variant="bodyBig" component="p" my={2}>
              {t('admin.question')} 3
            </Typography>

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="productCategory3"
              label="Filter Question"
              name="productCategory3"
              onChange={handleChange}
              error={!!errors.productCategory3}
              helperText={errors.productCategory3}
              value={''}
              color={!errors.productCategory3 && values.productCategory3 ? 'success' : ''}
            />

            <BaseSelect
              name="questionType3"
              label="Question Type"
              items={fakeOptionsList}
              initvalue={values.questionType3}
              sx={{ width: '100%', marginBottom: 0 }}
              onChange={(value) => setFieldValue('questionType3', value)}
            />

            <Grid container alignItems="center" mt={3}>
              <Grid item xs={6}>
                <BaseTextField
                  InputLabelProps={{ required: false }}
                  sx={{ marginBottom: '24px' }}
                  margin="normal"
                  fullWidth
                  id="selectionTags1"
                  name="selectionTags1"
                  label="Selection 1"
                  onChange={handleChange}
                  error={!!errors.selectionTags1}
                  helperText={errors.selectionTags1}
                  value={''}
                  color={!errors.selectionTags1 && values.selectionTags1 ? 'success' : ''}
                />
              </Grid>
              <Grid item xs={6} pl={2}>
                <BaseSelect
                  name="selectionTags11"
                  label="Tag 1"
                  items={fakeOptionsList}
                  initvalue={''}
                  sx={{ width: '100%', marginBottom: '12px' }}
                  onChange={(value) => setFieldValue('selectionTags11', value)}
                />
              </Grid>
            </Grid>

            <Grid container alignItems="center" mt={3}>
              <Grid item xs={6}>
                <BaseTextField
                  InputLabelProps={{ required: false }}
                  sx={{ marginBottom: '24px' }}
                  margin="normal"
                  fullWidth
                  id="selectionTags2"
                  name="selectionTags2"
                  label="Selection 2"
                  onChange={handleChange}
                  error={!!errors.selectionTags2}
                  helperText={errors.selectionTags2}
                  value={''}
                  color={!errors.selectionTags2 && values.selectionTags2 ? 'success' : ''}
                />
              </Grid>
              <Grid item xs={6} pl={2}>
                <BaseSelect
                  name="selectionTags12"
                  label="Tag 2"
                  items={fakeOptionsList}
                  initvalue={''}
                  sx={{ width: '100%', marginBottom: '12px' }}
                  onChange={(value) => setFieldValue('selectionTags12', value)}
                />
              </Grid>
            </Grid>

            <Grid container alignItems="center" mt={3}>
              <Grid item xs={6}>
                <BaseTextField
                  InputLabelProps={{ required: false }}
                  sx={{ marginBottom: '24px' }}
                  margin="normal"
                  fullWidth
                  id="selectionTags3"
                  name="selectionTags3"
                  label="Selection 3"
                  onChange={handleChange}
                  error={!!errors.selectionTags3}
                  helperText={errors.selectionTags3}
                  value={''}
                  color={!errors.selectionTags3 && values.selectionTags3 ? 'success' : ''}
                />
              </Grid>
              <Grid item xs={6} pl={2}>
                <BaseSelect
                  name="selectionTags13"
                  label="Tag 3"
                  items={fakeOptionsList}
                  initvalue={''}
                  sx={{ width: '100%', marginBottom: '12px' }}
                  onChange={(value) => setFieldValue('selectionTags13', value)}
                />
              </Grid>
            </Grid>

            <Typography variant="bodyBig" component="p" my={2}>
              {t('admin.question')} 4
            </Typography>

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="productCategory4"
              label="Filter Question"
              name="productCategory4"
              onChange={handleChange}
              error={!!errors.productCategory4}
              helperText={errors.productCategory4}
              value={''}
              color={!errors.productCategory4 && values.productCategory4 ? 'success' : ''}
            />

            <BaseSelect
              name="questionType4"
              label="Question Type"
              items={fakeOptionsList}
              initvalue={values.questionType4}
              sx={{ width: '100%', marginBottom: 0 }}
              onChange={(value) => setFieldValue('questionType4', value)}
            />

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="checkboxName"
              label="Checkbox Name"
              name="checkboxName"
              onChange={handleChange}
              error={!!errors.checkboxName}
              helperText={errors.checkboxName}
              value={''}
              color={!errors.checkboxName && values.checkboxName ? 'success' : ''}
            />

            <BaseSelect
              name="tag"
              label="Tag"
              items={fakeOptionsList}
              initvalue={values.tag}
              sx={{ width: '100%', marginBottom: 0 }}
              onChange={(value) => setFieldValue('tag', value)}
            />

            <BaseButton
              customColor={palette.variables.darkPurple}
              type="submit"
              fullWidth
              variant="contained"
              element={t('save')}
              sx={{ display: 'block', maxWidth: 300, marginTop: 5 }}
            />
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default FilterDetailsPage;
