import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, InputAdornment } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import PageLoading from 'components/PageLoading';
import BaseButton from 'components/formControl/baseButton/BaseButton';
import PhoneNumber from 'components/formControl/phoneNumber/PhoneNumber';
import BaseTextField from 'components/formControl/baseTextField/BaseTextField';
import BuildYourProfile from 'pages/accountSection/buildYourProfile/BuildYourProfile';
import { ReactComponent as IconCheckmark } from 'assets/icons/Icon-Checkmark.svg';

import { updateUserAttributes } from 'store/actions/user';
import palette from 'theme/palette';

const Step1 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user, loading, error, isUpdated } = useSelector((state) => state.user);

  const initialState = {
    name: user?.attributes?.name || '',
    family_name: user?.attributes?.family_name || '',
    phoneNumber: user?.attributes?.phoneNumber || '',
    address: user?.attributes?.address || ''
  };

  const Step1Schema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    phoneNumber: Yup.string().required(t('validation.required')).min(10),
    address: Yup.string(),
    family_name: Yup.string()
  });

  useEffect(() => {
    error && navigate('/build-your-profile/error?redirect=/build-your-profile/step-1');
  }, [error]);

  useEffect(() => {
    isUpdated && setNextStep();
  }, [isUpdated]);

  // const AutoAddData = () => {
  //   const { values, setValues } = useFormikContext();
  //   useEffect(() => {
  //     if (user.attributes) {
  //       const { name, address, phone_number } = user.attributes;
  //       setValues({ ...values, name, address, phoneNumber: phone_number });
  //     }
  //   }, [user]);
  //   return null;
  // };

  const setNextStep = () => {
    navigate('/build-your-profile/step-3');
  };

  const handleSubmit = async (values) => {
    // const users = await Auth.currentAuthenticatedUser();
    const attributes = {
      address: values.address,
      phone_number: values.phoneNumber,
      name: values.name,
      family_name: values.family_name
    };
    dispatch(updateUserAttributes({ user: user, attributes }));
  };

  return (
    <BuildYourProfile title={t('buildYourProfile')} linearProgress={50} stepTitle={t('step1Of2')}>
      <Formik
        style={{ width: '100%' }}
        onSubmit={handleSubmit}
        initialValues={initialState}
        validationSchema={Step1Schema}>
        {({ errors, values, handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <Box component="div" sx={{ width: '100%' }}>
              <BaseTextField
                sx={{ marginBottom: '16px' }}
                margin="normal"
                fullWidth
                id="name"
                label={t('name') + ' *'}
                name="name"
                autoComplete="name"
                error={!!errors.name}
                helperText={errors.name}
                onChange={handleChange}
                value={values.name}
                color={!errors.name && values.name ? 'success' : ''}
                InputProps={{
                  endAdornment:
                    !errors.name && values.name ? (
                      <InputAdornment position="end">
                        <IconCheckmark />
                      </InputAdornment>
                    ) : (
                      <div />
                    )
                }}
              />
              <BaseTextField
                InputLabelProps={{ required: false }}
                sx={{ marginBottom: '16px' }}
                margin="normal"
                fullWidth
                id="family_name"
                label={t('surname')}
                name="family_name"
                autoComplete="family_name"
                error={!!errors.family_name}
                helperText={errors.family_name}
                onChange={handleChange}
                value={values.family_name}
              />
              <PhoneNumber
                sx={{ marginBottom: '16px' }}
                id="phoneNumber"
                label={t('phoneNumber') + ' *'}
                name="phoneNumber"
                helperText={errors.phoneNumber}
                error={!!errors.phoneNumber}
                value={values.phoneNumber}
                onChange={handleChange}
                className={!errors.phoneNumber && !!values.phoneNumber ? 'success' : ''}
                InputProps={{
                  endAdornment:
                    !errors.phoneNumber && values.phoneNumber ? (
                      <InputAdornment position="end">
                        <IconCheckmark />
                      </InputAdornment>
                    ) : (
                      <div />
                    )
                }}
              />
              <BaseTextField
                InputLabelProps={{ required: false }}
                sx={{ marginBottom: '48px' }}
                margin="normal"
                fullWidth
                id="address"
                label={t('address')}
                name="address"
                autoComplete="address"
                onChange={handleChange}
                error={!!errors.address}
                helperText={errors.address}
                value={values.address}
              />
              <BaseButton
                customColor={palette.variables.darkPurple}
                type="submit"
                fullWidth
                variant="contained"
                element={t('next')}
                sx={{ marginBottom: '20px' }}
              />
            </Box>
            {/* <AutoAddData /> */}
            <PageLoading loading={loading} />
          </form>
        )}
      </Formik>
    </BuildYourProfile>
  );
};

export default Step1;
