import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ReactComponent as GoogleIcon } from '../assets/icons/google-icon.svg';

export default function GoogleSign({ onClick, ...props }) {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" sx={props.sx}>
      <Grid item onClick={onClick}>
        <GoogleIcon style={{ marginBottom: '8px' }} />
        <Typography
          sx={{ margin: 0 }}
          variant="caption"
          align="center"
          display="block"
          gutterBottom>
          {t('Google')}
        </Typography>
      </Grid>
    </Grid>
  );
}

GoogleSign.defaultProps = {
  sx: {},
  onClick: () => ({})
};
GoogleSign.propTypes = {
  sx: PropTypes.object,
  onClick: PropTypes.func
};
