import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ReactComponent as AppleIcon } from '../assets/icons/Icon-Apple.svg';

export default function AppleSign({ onClick, ...props }) {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" sx={props.sx}>
      <Grid item onClick={onClick}>
        <AppleIcon style={{ marginBottom: '8px' }} />
        <Typography
          sx={{ margin: 0 }}
          variant="caption"
          align="center"
          display="block"
          gutterBottom>
          {t('Apple ID')}
        </Typography>
      </Grid>
    </Grid>
  );
}

AppleSign.defaultProps = {
  sx: {},
  onClick: () => ({})
};
AppleSign.propTypes = {
  sx: PropTypes.object,
  onClick: PropTypes.func
};
