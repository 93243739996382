import { Navigate, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';

import GuardedLayout from 'layouts/GuardedLayout';
import MainLayout from 'layouts/mainLayout/MainLayout';
import PublicLayout from 'layouts/PublicLayout';
import Dashboard from 'pages/dashboardSection/dashboard/Dashboard';
import SignUp from 'pages/accountSection/signUp/SignUp';
import SignIn from 'pages/accountSection/signIn/SignIn';
import CardCategories from 'pages/productsSection/products/ProductsCategories';
import ProductsList from 'pages/productsSection/products/ProductsList';
import Product from 'pages/productsSection/product/Product';
import NoMatch from 'pages/NoMatch';
import Notifications from 'pages/dashboardSection/notifications/Notifications';
import Notification from 'pages/dashboardSection/Notification/Notification';
import Step1 from 'pages/accountSection/buildYourProfile/steps/Step1';
import Step2 from 'pages/accountSection/buildYourProfile/steps/Step2';
import Step3 from 'pages/accountSection/buildYourProfile/steps/Step3';
import Congratulations from 'pages/accountSection/buildYourProfile/Congratulations';
import ForgotPassword from 'pages/accountSection/forgotPassword/ForgotPassword';
import ForgotPasswordLayout from 'layouts/forgotPasswordLayout/ForgotPasswordLayout';
import ForgotPasswordCode from 'pages/accountSection/forgotPassword/steps/forgotPasswordCode/ForgotPasswordCode';
import ResetPassword from 'pages/accountSection/forgotPassword/steps/resetPassword/ResetPassword';
import Verification from 'pages/accountSection/verification/Verification';
import SuccessVerification from 'pages/accountSection/verification/steps/SuccessVerification';
import ErrorVerification from 'pages/accountSection/verification/steps/ErrorVerification';
import ChangePasswordSuccess from 'pages/accountSection/forgotPassword/steps/ChangePasswordSuccess';
import ChangePasswordError from 'pages/accountSection/forgotPassword/steps/ChangePasswordError';
import SettingsPage from 'pages/settingsPage/SettingsPage';

import 'amplify/index';
import HistoryLayout from 'layouts/historyLayout/HistoryLayout';
import ProductsLayout from 'layouts/productsLayout/ProductsLayout';
import Review from 'pages/productsSection/review/Review';
import SignUpLayout from 'layouts/signUpLayout/SignUpLayout';
import ExpertOpinion from 'pages/productsSection/expertOpinion/ExpertOpinion';
import ApplyNow from 'pages/productsSection/applyNow/ApplyNow';
import 'utils/logger';
import ProductReview from '../pages/productReview/ProductReview';
// Resources Pages
import ResourcesPage from 'pages/resourcesPages/ResourcesPage';
import ExpertsPage from 'pages/resourcesPages/ExpertsPage/ExpertsPage';
import CommunityPage from 'pages/resourcesPages/CommunityPage';
import GuidebooksPage from 'pages/resourcesPages/GuidebooksPage';
import GuidebookDetailsPage from 'pages/resourcesPages/GuidebookDetailsPage';
import FAQsPage from 'pages/resourcesPages/FAQsPage';
// Point Pages
import PointsPage from 'pages/pointsPages/PointsPage';
import CalculatorPage from 'pages/calculatorPage/CalculatorPage';
// Admin Pages
import AdminLayout from 'layouts/adminLayout/AdminLayout';
import SignInAdminPage from 'pages/adminPages/SignInAdminPage';
import DashboardAdminPage from 'pages/adminPages/DashboardAdminPage';
import PartnersAdminPage from 'pages/adminPages/PartnersAdminPage/PartnersAdminPage';
import PartnerCreateAdminPage from 'pages/adminPages/PartnersAdminPage/PartnerCreateAdminPage';
import PartnerDetailsAdminPage from 'pages/adminPages/PartnersAdminPage/PartnerDetailsAdminPage';
import ProductsAdminPage from 'pages/adminPages/ProductsAdminPage/ProductsAdminPage';
import ProductTagsAdminPage from 'pages/adminPages/ProductsAdminPage/ProductTagsAdminPage';
import ProductDetailsAdminPage from 'pages/adminPages/ProductsAdminPage/ProductDetailsAdminPage';
import ProductCategoryAdminPage from 'pages/adminPages/ProductCategoryAdminPage/ProductCategoryAdminPage';
import ProductCategoryDetailsAdminPage from 'pages/adminPages/ProductCategoryAdminPage/ProductCategoryDetailsAdminPage';
import ExpertsAdminPage from 'pages/adminPages/ExpertsAdminPage';
import ResourcesAdminPage from 'pages/adminPages/ResourcesAdminPage';
import QuizzesAdminPage from 'pages/adminPages/QuizzesAdminPage';
import TagsAdminPage from 'pages/adminPages/TagsAdminPage';
import UsersAdminPage from 'pages/adminPages/UsersAdminPage';
import SettingsAdminPage from 'pages/adminPages/SettingsAdminPage';
import ActivityAdminPage from 'pages/adminPages/ActivityAdminPage';
import CongratsAdminPage from 'pages/adminPages/CongratsAdminPage';
import FilterAdminPage from 'pages/adminPages/FilterAdminPage/FilterAdminPage';
import FilterDetailsAdminPage from 'pages/adminPages/FilterAdminPage/FilterDetailsAdminPage';

import { currentAuthenticatedUser } from 'store/actions/user';
import { setStateValue } from 'store/reducers/user';

const publicRoutes = [
  {
    path: '/',
    element: <PublicLayout />,
    children: [
      {
        path: '/',
        element: <SignUpLayout />,
        children: [
          {
            path: 'signIn',
            element: <SignIn />
          },
          {
            path: 'signUp',
            element: <SignUp />
          }
        ]
      },
      {
        path: '/calculator',
        element: <CalculatorPage />
      }
    ]
  },
  {
    path: '/verification',
    element: <ForgotPasswordLayout />,
    children: [
      {
        path: '',
        element: <Verification />
      },
      {
        path: 'success',
        element: <SuccessVerification />
      },
      {
        path: 'error',
        element: <ErrorVerification />
      }
    ]
  }
];

const routes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.pathname || '/';

  const { user } = useSelector((state) => state.user);

  const [isAuth, setAuth] = useState(false);

  useEffect(async () => {
    const { payload } = await dispatch(currentAuthenticatedUser());
    if (!payload?.attributes?.name) {
      await navigate('/build-your-profile/step-1', { replace: true });
    } else if (!payload?.attributes) {
      await navigate('/build-your-profile/step-1', { replace: true });
    } else {
      setAuth(true);
      await navigate(from, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (user?.username) {
      setAuth(true);
    } else if (!user?.attributes || (user.attributes && !user?.attributes?.name)) {
      navigate('/build-your-profile/step-1', { replace: true });
    } else {
      navigate(from, { replace: true });
    }
  }, [user]);

  useEffect(() => {
    if (isAuth) {
      navigate(from, { replace: true });
    } else {
      getToken();
      // navigate('/signIn', { replace: true });
    }
  }, [isAuth]);

  const getToken = async () => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    if (token) {
      setAuth(true);
    }
  };

  const goTo = (path, params) => navigate(path, params);

  Hub.listen('auth', ({ payload: { event, data } }) => {
    console.log('auth.event', event, data);
    switch (event) {
      case 'signIn':
        if (!data.attributes?.name) {
          setTimeout(() => {
            goTo('/build-your-profile/step-1', { replace: true });
          });
        }
        if (data.attributes?.name) {
          setTimeout(() => {
            goTo('/', { replace: true });
          });
        }
        break;
      case 'signUp':
        setAuth(true);
        setTimeout(() => {
          goTo('/verification', { replace: true });
        });
        break;
      case 'cognitoHostedUI':
        if (data?.attributes && !data.attributes.name) {
          goTo('/build-your-profile/step-1', { replace: true });
        }
        break;
      case 'signOut':
        break;
      case 'signIn_failure':
        dispatch(setStateValue({ type: 'error', data: data.message }));
        break;
      case 'cognitoHostedUI_failure':
        goTo('/401', { replace: true });
        // window.location.replace('/401');
        break;
    }
  });

  const routes = [
    {
      path: '/',
      element: isAuth ? <GuardedLayout /> : <Navigate to="/signIn" replace />,
      children: [
        {
          path: '/',
          element: <MainLayout />,
          children: [
            { path: '/', element: <Dashboard /> },
            { path: '/resources', element: <ResourcesPage /> },
            { path: '/points', element: <PointsPage /> },
            { path: '/settings', element: <SettingsPage /> }
          ]
        },
        {
          path: '/',
          element: <HistoryLayout />,
          children: [
            { path: '/', element: <Dashboard /> },
            { path: 'resources/experts', element: <ExpertsPage /> },
            { path: 'resources/guidebooks', element: <GuidebooksPage /> },
            { path: 'resources/guidebooks/:id', element: <GuidebookDetailsPage /> },
            { path: 'resources/community', element: <CommunityPage /> },
            { path: 'resources/faqs', element: <FAQsPage /> }
          ]
        },
        {
          path: '/',
          element: <HistoryLayout />,
          children: [
            {
              path: '/notifications',
              element: <Notifications />
            },
            {
              path: '/notification/:item',
              element: <Notification />
            },
            {
              path: '/',
              element: <ProductsLayout />,
              children: [
                {
                  path: '/card-categories/:type',
                  element: <CardCategories />
                },
                {
                  path: '/card-categories/:product',
                  element: <ProductsList />
                }
              ]
            },
            {
              path: '/expert-opinion',
              element: <ExpertOpinion />
            },
            {
              path: '/product/:slug',
              element: <Product />
            },
            {
              path: '/review/:slug',
              element: <Review />
            },
            {
              path: '/apply-now',
              element: <ApplyNow />
            },
            {
              path: '/product-review/:slug',
              element: <ProductReview />
            }
          ]
        },
        {
          path: '/',
          element: <SignUpLayout />,
          children: [
            {
              path: '/build-your-profile',
              children: [
                { path: 'step-1', element: <Step1 /> },
                { path: 'step-2', element: <Step2 /> },
                { path: 'step-3', element: <Step3 /> },
                { path: 'congratulations', element: <Congratulations /> },
                { path: 'error', element: <ChangePasswordError /> }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/forgot-password',
      element: <ForgotPasswordLayout />,
      children: [
        {
          path: 'input/:type',
          element: <ForgotPassword />
        },
        {
          path: 'input',
          element: <ForgotPassword />
        },
        {
          path: 'reset/:type',
          element: <ResetPassword />
        },
        {
          path: 'reset',
          element: <ResetPassword />
        },
        {
          path: 'code/:type',
          element: <ForgotPasswordCode />
        },
        {
          path: 'code',
          element: <ForgotPasswordCode />
        },
        {
          path: 'success',
          element: <ChangePasswordSuccess />
        },
        {
          path: 'error',
          element: <ChangePasswordError />
        }
      ]
    },
    ...publicRoutes,
    {
      path: '/401',
      element: <NoMatch />
    },
    {
      path: '/',
      element: <AdminLayout />,
      children: [
        { path: 'admin', element: <SignInAdminPage /> },
        { path: 'admin/congrats', element: <CongratsAdminPage /> },
        { path: 'admin/dashboard', element: <DashboardAdminPage /> },
        { path: 'admin/partners', element: <PartnersAdminPage /> },
        { path: 'admin/partners/create', element: <PartnerCreateAdminPage /> },
        { path: 'admin/partners/details/:id', element: <PartnerDetailsAdminPage /> },
        { path: 'admin/products', element: <ProductsAdminPage /> },
        { path: 'admin/products/tags', element: <ProductTagsAdminPage /> },
        { path: 'admin/products/tags/:id', element: <ProductDetailsAdminPage /> },
        { path: 'admin/product-category', element: <ProductCategoryAdminPage /> },
        {
          path: 'admin/product-category/:id',
          element: <ProductCategoryDetailsAdminPage />
        },
        { path: 'admin/experts', element: <ExpertsAdminPage /> },
        { path: 'admin/resources', element: <ResourcesAdminPage /> },
        { path: 'admin/quizzes', element: <QuizzesAdminPage /> },
        { path: 'admin/tags', element: <TagsAdminPage /> },
        { path: 'admin/users', element: <UsersAdminPage /> },
        { path: 'admin/settings', element: <SettingsAdminPage /> },
        { path: 'admin/activity', element: <ActivityAdminPage /> },
        { path: 'admin/filter', element: <FilterAdminPage /> },
        { path: 'admin/filter/:id', element: <FilterDetailsAdminPage /> }
      ]
    },
    {
      path: '*',
      element: <SignUpLayout />,
      children: [
        {
          path: '*',
          element: <NoMatch />
        }
      ]
    }
  ];

  return useRoutes(routes);
};

export default routes;
