import React from 'react';
import { Box, Typography } from '@mui/material';

import styles from './admin.module.scss';

const ActivityAdminPage = () => {
  return (
    <Box className={styles.container}>
      <Typography>ADMIN ActivityAdminPage</Typography>
    </Box>
  );
};

export default ActivityAdminPage;
