import { configureStore } from '@reduxjs/toolkit';
import appReducer from './app/appSlice';
// import userReducer from './user/userSlice';
import userReducer from './reducers/user';
import productsReducer from './reducers/products';
import dashboardReducer from './reducers/dashboard';
import resourcesReducer from './reducers/resources';
import pointsReducer from './reducers/points';
import partnersReducer from './reducers/partners';

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    products: productsReducer,
    dashboard: dashboardReducer,
    resources: resourcesReducer,
    points: pointsReducer,
    partners: partnersReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
