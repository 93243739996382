import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import './baseTextField.scss';

export default function BaseSelect({
  items = [],
  initvalue = 0,
  onChange,
  label = '',
  sx,
  variant = 'standard'
}) {
  const [value, setValue] = useState('');

  useEffect(() => {
    initvalue && setValue(initvalue);
  }, [initvalue]);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange && onChange(e.target.value);
  };

  return (
    <FormControl
      variant={variant}
      sx={{ m: 1,  minWidth: 120, background: 'transparent', ...sx }}>
      {!!label && <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>}
      <Select
        value={value}
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        onChange={handleChange}
        sx={{ background: 'transparent !important' }}
        label="Age">
        {items.map((item, index) => (
          <MenuItem key={index} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

BaseSelect.propTypes = {
  items: PropTypes.array,
  initvalue: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  sx: PropTypes.obj,
  variant: PropTypes.string
};
