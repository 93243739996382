import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Box, InputAdornment, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import BaseButton from 'components/formControl/baseButton/BaseButton';
import BaseTextField from 'components/formControl/baseTextField/BaseTextField';
import { ReactComponent as IconLock } from 'assets/icons/Icon-Lock.svg';
import { ReactComponent as IconShow } from 'assets/icons/Icon-show.svg';
import { ReactComponent as IconHide } from 'assets/icons/Icon-hide.svg';

import { setTempPassword } from 'store/reducers/user';
import { forgotPassword } from 'store/actions/user';

import palette from 'theme/palette';
import styles from './resetPassword.module.scss';

export default function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();
  const { t } = useTranslation();

  const { email } = useSelector((state) => state.user);

  const [passwordType, setPasswordType] = useState('password');
  const [passwordRepeatType, setPasswordRepeatType] = useState('password');

  const initialState = {
    password: '',
    passwordRepeat: ''
  };

  const additionalSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('validation.required'))
      .test('is-password', t('validation.password'), (value) => {
        if (
          /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).+$/.test(value) &&
          value.length > 8 &&
          value.length < 128
        ) {
          return true;
        }
        return false;
      }),

    passwordRepeat: Yup.string()
      .required(t('validation.required'))
      .test('is-mismatch-password', t('validation.passwordMismatch'), (value, { parent }) => {
        return parent.password === value;
      })
  });

  const handleSubmit = (values) => {
    dispatch(forgotPassword(email));
    dispatch(setTempPassword(values.password));
    navigate('/forgot-password/code' + '/' + params.type || '');
  };

  return (
    <Container component="main" maxWidth="xs" style={{ paddingBottom: '40px', marginTop: '115px' }}>
      <Formik
        validationSchema={additionalSchema}
        onSubmit={handleSubmit}
        initialValues={initialState}>
        {({ values, handleSubmit, handleChange, errors }) => (
          <form onSubmit={handleSubmit}>
            <Box
              className={styles.formContainer}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <Typography component="h2" variant="h3" sx={{ marginBottom: '16px' }}>
                {t('resetPassword')}
              </Typography>

              <Typography
                component="h3"
                variant="body2"
                align="center"
                sx={{ marginBottom: '24px' }}
                color="common.grey">
                {t('atLeastCharacters')}
              </Typography>

              <Box component="div" sx={{ width: '100%' }}>
                <BaseTextField
                  InputLabelProps={{ required: false }}
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  className="password-type"
                  type={passwordType}
                  id="password"
                  error={!!errors.password}
                  helperText={errors.password}
                  onChange={handleChange}
                  value={values.password}
                  color={!errors.password && values.password ? 'success' : ''}
                  sx={{ marginBottom: '30px' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconLock />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() =>
                          setPasswordType(passwordType === 'text' ? 'password' : 'text')
                        }>
                        {passwordType === 'text' ? <IconShow /> : <IconHide />}
                      </InputAdornment>
                    )
                  }}
                />

                <BaseTextField
                  InputLabelProps={{ required: false }}
                  margin="normal"
                  fullWidth
                  name="passwordRepeat"
                  label="Password Repeat"
                  type={passwordRepeatType}
                  id="passwordRepeat"
                  className="password-type"
                  error={!!errors.passwordRepeat}
                  helperText={errors.passwordRepeat}
                  onChange={handleChange}
                  value={values.passwordRepeat}
                  color={!errors.passwordRepeat && values.passwordRepeat ? 'success' : ''}
                  sx={{ marginBottom: '45px' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconLock />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() =>
                          setPasswordRepeatType(passwordRepeatType === 'text' ? 'password' : 'text')
                        }>
                        {passwordRepeatType === 'text' ? <IconShow /> : <IconHide />}
                      </InputAdornment>
                    )
                  }}
                />

                <BaseButton
                  customColor={palette.variables.darkPurple}
                  type="submit"
                  fullWidth
                  variant="contained"
                  element={t('resetPassword')}
                />
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Container>
  );
}
