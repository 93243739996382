import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import palette from 'theme/palette';
import styles from 'pages/accountSection/signUp/signUp.module.scss';
import { ReactComponent as IconTrust } from 'assets/icons/Icon-Trust.svg';
import { ReactComponent as IconWealth } from 'assets/icons/Icon-Wealth.svg';
import BaseButton from 'components/formControl/baseButton/BaseButton';
import BuildYourProfile from 'pages/accountSection/buildYourProfile/BuildYourProfile';

export default function Step2() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setNextStep = () => {
    navigate('/build-your-profile/step-3');
  };

  return (
    <BuildYourProfile title={t('buildYourProfile')} stepTitle={t('step2Of3')} linearProgress={66}>
      <Box>
        <Box sx={{ width: '100%' }}>
          <Typography
            sx={{ marginBottom: '16px' }}
            color="common.darkPurple"
            variant="body2"
            align="left"
            display="block"
            gutterBottom>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur ac elementum metus,
            vel quis morbi nisl. Non, sit volutpat ullamcorper phasellus risus id. Proin nibh
            volutpat sagittis donec nisl faucibus mauris sit justo. Neque amet felis volutpat
            molestie pellentesque.
          </Typography>
          <Typography
            sx={{ marginBottom: '16px' }}
            color="common.darkPurple"
            variant="bodyBig"
            align="left"
            display="block"
            gutterBottom>
            {t('startYourWealth')}
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: '33px'
          }}>
          <Grid
            item
            xs={6}
            sx={{
              minHeight: '100%',
              display: 'flex'
            }}>
            <Box className={[styles.journeyBox, styles.financial].join(' ')}>
              <IconWealth />
              <Typography
                color="common.darkPurple"
                variant="subtitle1"
                align="left"
                sx={{
                  maxWidth: '113px'
                }}
                display="block"
                gutterBottom>
                Finanical knowledge
              </Typography>
              <Typography
                color={palette.variables.greyLight}
                variant="caption"
                align="left"
                display="block"
                gutterBottom>
                Test your knowledge and unlock 50 points!
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              minHeight: '100%',
              display: 'flex'
            }}>
            <Box className={[styles.journeyBox, styles.physical].join(' ')}>
              <IconTrust />
              <Typography
                color="common.darkPurple"
                variant="subtitle1"
                align="left"
                display="block"
                sx={{
                  maxWidth: '113px'
                }}
                gutterBottom>
                Physical activity
              </Typography>
              <Typography
                color={palette.variables.greyLight}
                variant="caption"
                align="left"
                display="block"
                gutterBottom>
                Test your knowledge and unlock 50 points!
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <BaseButton
          type="text"
          onClick={setNextStep}
          sx={{ display: 'flex', margin: 'auto', minHeight: 'initial', padding: '0' }}
          element={
            <Typography
              sx={{ marginBottom: 0 }}
              color={palette.white['400']}
              variant="caption"
              align="center"
              display="block"
              gutterBottom>
              {t('skipForNow')}
            </Typography>
          }
        />
      </Box>
    </BuildYourProfile>
  );
}
