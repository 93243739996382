import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, ListItemButton, List, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { ReactComponent as Logo } from 'assets/images/illustrations/logo-default.svg';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import palette from 'theme/palette';

const Sidebar = ({ items, open, setOpen }) => {
  const theme = useTheme();
  return (
    <Drawer
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box'
        }
      }}
      variant="persistent"
      anchor="left"
      open={open}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ListItemButton component={NavLink} to="/admin/dashboard" sx={{ maxHeight: 70 }}>
          <Logo /> <Box ml={1}>Daleel</Box>
        </ListItemButton>
        <IconButton
          sx={{ height: 'max-content' }}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>

      <List disablePadding>
        <Box>
          {items?.map((item) => (
            <ListItemButton key={item.id} component={NavLink} to={item.to} sx={{ maxHeight: 70 }}>
              <Typography sx={{ padding: 5 }} variant="body1" color={palette.variables.darkPurple}>
                {item.name}
              </Typography>
            </ListItemButton>
          ))}
        </Box>
      </List>
    </Drawer>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  items: PropTypes.array,
  showFooter: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};
