import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import palette from 'theme/palette';
import BaseButton from 'components/formControl/baseButton/BaseButton';
import BuildYourProfile from 'pages/accountSection/buildYourProfile/BuildYourProfile';
import { ReactComponent as AlertTriangle } from 'assets/icons/Alert-triangle.svg';
import { ReactComponent as AppleDownload } from 'assets/images/illustrations/AppleDownload.svg';
import { ReactComponent as GoogleDownload } from 'assets/images/illustrations/GoogleDownload.svg';

export default function Step3() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setNextStep = () => {
    navigate('/build-your-profile/congratulations');
  };
  return (
    <BuildYourProfile title={t('connectHealthData')} linearProgress={100} stepTitle={t('step2Of2')}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex' }}>
          <AlertTriangle style={{ marginRight: '8px', display: 'block', minWidth: '24px' }} />
          <Typography
            sx={{ marginBottom: '45px' }}
            color="common.darkPurple"
            variant="body2"
            align="left"
            display="block"
            gutterBottom>
            {t('dataCannotConnected')}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: '22px' }}>
          <AppleDownload style={{ margin: '0 19px 0 0' }} />
          <GoogleDownload />
        </Box>
        <BaseButton
          type="text"
          onClick={setNextStep}
          sx={{ display: 'flex', margin: 'auto', minHeight: 'initial', padding: '0' }}
          element={
            <Typography
              sx={{ marginBottom: 0 }}
              color={palette.white['400']}
              variant="caption"
              align="center"
              display="block"
              gutterBottom>
              {t('skipForNow')}
            </Typography>
          }
        />
      </Box>
    </BuildYourProfile>
  );
}
