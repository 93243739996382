import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import VerifyCode from 'views/VerifyCode';
import { setStateValue } from 'store/reducers/user';
import { forgotPasswordSubmit, forgotPassword } from 'store/actions/user';

export default function ForgotPasswordCode() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // let params = useParams();
  // const redirectLinkText = params.type === 'email' ? t('recoverViaPhone') : t('recoverViaEmail');

  const {
    email,
    tempPassword,
    deadlineStart,
    forgotPasswordResp,
    error,
    loading,
    isChangedPassword
  } = useSelector((state) => state.user);

  const [code, setCode] = useState({});

  useEffect(() => {
    error && navigate('/forgot-password/error');
  }, [error]);

  useEffect(() => {
    isChangedPassword && navigate('/forgot-password/success');
  }, [isChangedPassword]);

  const onVerify = async () => {
    dispatch(
      forgotPasswordSubmit({
        email: email,
        code: Object.values(code).join(''),
        new_password: tempPassword
      })
    );
  };

  const onCodeChange = (value) => {
    dispatch(setStateValue({ type: 'error', data: '' }));
    setCode(value);
  };

  const onResend = async () => {
    if (!deadlineStart) {
      dispatch(setStateValue({ type: 'deadlineStart', data: true }));
      dispatch(forgotPassword(email));
    }
  };

  return (
    <VerifyCode
      // redirectLink="/forgot-password/input"
      // redirectLinkText={redirectLinkText}
      // redirectUrlParams={{ type: params.type === 'email' ? 'phoneNumber' : 'email' }}
      title={t('forgotPassword')}
      subTitle={t('sentPassword', {
        method: forgotPasswordResp?.DeliveryMedium.toLowerCase() || '',
        email: forgotPasswordResp?.Destination || ''
      })}
      buttonText={t('verify')}
      onVerify={onVerify}
      error={error}
      onResend={onResend}
      buttonLoading={loading}
      onInput={onCodeChange}
    />
  );
}
