import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import BaseButton from 'components/formControl/baseButton/BaseButton';
import BaseTextField from 'components/formControl/baseTextField/BaseTextField';
import ErrorNotification from 'components/ErrorNotification';
import PageLoading from 'components/PageLoading';
import { ReactComponent as IconAtSign } from 'assets/icons/Icon-At-Sign.svg';
import { ReactComponent as IconCheckmark } from 'assets/icons/Icon-Checkmark.svg';
import { ReactComponent as IconLock } from 'assets/icons/Icon-Lock.svg';
import { ReactComponent as LogoDefault } from 'assets/images/illustrations/logo-default.svg';

import palette from 'theme/palette';
import styles from './admin.module.scss';

const SignInAdminPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const initialState = { email: '', password: '' };

  const SigninSchema = Yup.object().shape({
    password: Yup.string().required(t('validation.required')),
    email: Yup.string().email(t('validation.email')).required(t('validation.required'))
  });

  const handleSubmit = (values) => {
    console.log('handleSubmit___', values);
    navigate('dashboard');
  };

  return (
    <Grid container spacing={1} alignItems="center" className={styles.container} height="100%">
      <Grid item md={6} xs={12} display="flex" direction="column" alignItems="center" p={3}>
        <Typography variant="h3" color={palette.variables.darkPurple} mb={3}>
          {`Welcome to Daleel's Admin Panel`}
        </Typography>
        <LogoDefault />
      </Grid>

      <Grid item md={6} xs={12} p={3}>
        <Formik
          initialValues={initialState}
          validationSchema={SigninSchema}
          onSubmit={handleSubmit}>
          {({ values, errors, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Typography
                sx={{ marginBottom: '16px' }}
                component="h2"
                variant="h3"
                color={palette.variables.darkPurple}>
                {t('logIn')}
              </Typography>

              {!errors && <ErrorNotification sx={{ marginBottom: '19px' }} errorText={'errors'} />}

              <Box component="div">
                <BaseTextField
                  InputLabelProps={{ required: false }}
                  sx={{ marginBottom: '24px' }}
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  value={values.email}
                  color={!errors.email && values.email ? 'success' : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconAtSign />
                      </InputAdornment>
                    ),
                    endAdornment:
                      !errors.email && values.email ? (
                        <InputAdornment position="end">
                          <IconCheckmark />
                        </InputAdornment>
                      ) : (
                        <div />
                      )
                  }}
                />

                <BaseTextField
                  InputLabelProps={{ required: false }}
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  error={!!errors.password}
                  helperText={errors.password}
                  onChange={handleChange}
                  value={values.password}
                  color={!errors.password && values.password ? 'success' : ''}
                  sx={{ marginBottom: '30px' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconLock />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <BaseButton
                        variant="text"
                        onClick={() => navigate('/forgot-password/input')}
                        element={
                          <Typography
                            variant="caption"
                            align="center"
                            display="block"
                            color={palette.variables.green}
                            gutterBottom>
                            {t('forgot')}?
                          </Typography>
                        }
                      />
                    )
                  }}
                />

                <Box>
                  <BaseButton
                    customColor={palette.variables.darkPurple}
                    type="submit"
                    fullWidth
                    variant="contained"
                    element={t('logIn')}
                    sx={{ display: 'block', maxWidth: 300, margin: ' 30px auto' }}
                  />
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Grid>

      <PageLoading loading={false} />
    </Grid>
  );
};

export default SignInAdminPage;
