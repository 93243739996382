import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';

import ItemDashboard from 'views/Admin/Dashboard/ItemDashboard';
import BaseButton from 'components/formControl/baseButton/BaseButton';

import { productsOptions } from 'utils/fakeValues';
import { fetchProducts } from 'store/actions/products';
import { setStateProductValue } from 'store/reducers/products';

import palette from 'theme/palette';
import styles from '../admin.module.scss';

const ProductsAdminPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchProducts({ categoryId: 1, tagId: 1 }));
  }, []);

  const onGoToProduct = (product) => {
    console.log('product', product);
    dispatch(setStateProductValue({ type: 'product', data: product }));
    navigate('/admin/products/tags');
  };

  const onCreateProduct = () => {
    navigate('/admin/products/tags/0');
  };

  return (
    <Box className={styles.container}>
      <Grid container alignItems="center" justifyContent="space-between" mb={3}>
        <Grid item>
          <Typography variant="h3" mt={3}>
            {t('admin.products')}
          </Typography>
        </Grid>

        <Grid item sx={{ display: 'flex', alignItems: 'end' }}>
          <BaseButton
            customColor={palette.variables.orange}
            variant="contained"
            element={t('add')}
            sx={{ display: 'block', maxWidth: 300, height: 'max-content', marginLeft: 3 }}
            onClick={onCreateProduct}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="stretch">
        {productsOptions?.map((item) => (
          <Grid item md={4} xs={6} key={item.id}>
            <ItemDashboard item={item} onClick={() => onGoToProduct(item)} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductsAdminPage;
