const init = {
  headers: {
    // 'Access-Control-Allow-Origin': '*'
  }
  // response: true
};
export default class RequestService {
  constructor(apiName, path, httpService) {
    this._apiName = apiName;
    this._path = path;
    this._httpService = httpService;
  }

  async getDataList({ params }) {
    try {
      const response = await this._httpService.get(this._apiName, this._path, {
        ...init,
        queryStringParameters: params
      });
      return response;
    } catch (e) {
      throw new Error(e);
    }
  }

  async getDataById({ params }) {
    try {
      const response = await this._httpService.get(this._apiName, `${this._path}/${params.id}`);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  }
}
