export default {
  variables: {
    green: '#69B5B5',
    lightPurple: '#DEA8DE',
    darkPurple: '#29173B',
    coolWhite: '#F2F2F2',
    pink: '#F29191',
    error: '#C86B6D',
    grey: '#DDDDDD',
    lightGreen: '#69B5B5',
    orange: '#F29469',
    black: '#000000',
    white: '#FFFFFF',
    greyLight: '#9B9B9B'
  },
  darkPurple: {
    900: '#453157',
    800: '#58436B',
    700: '#6C557F',
    600: '#806994',
    500: '#957DA9',
    400: '#AA92BF',
    300: '#C0A7D5',
    200: '#D7BDEC',
    100: '#EDD3FF',
    50: '#FFEAFF'
  },
  orange: {
    900: '#6A2000',
    800: '#81340E',
    700: '#984721',
    600: '#AF5B33',
    500: '#C76F46',
    400: '#DF8459',
    300: '#F7996D',
    200: '#FFAE82',
    100: '#FFC597',
    50: '#FFDBAD'
  },
  lightPurple: {
    900: '#552857',
    800: '#693A6A',
    700: '#7D4D7F',
    600: '#926193',
    500: '#A875A9',
    400: '#BE8ABE',
    300: '#D59FD5',
    200: '#EBB5EB',
    100: '#FFCBFF',
    50: '#FFE1FF'
  },
  pink: {
    900: '#6D1A23',
    800: '#832F34',
    700: '#994346',
    600: '#B05759',
    500: '#C86B6D',
    400: '#DF8081',
    300: '#F79695',
    200: '#FFABAB',
    100: '#FFC2C0',
    50: '#FFD8D7'
  },
  green: {
    900: '#004244',
    800: '#005556',
    700: '#11696A',
    600: '#2E7D7E',
    500: '#459292',
    400: '#5BA7A7',
    300: '#71BDBD',
    200: '#87D3D3',
    100: '#9DEAEA',
    50: '#B4FFFF'
  },
  white: {
    900: '#393939',
    800: '#4B4B4B',
    700: '#5E5E5E',
    600: '#727272',
    500: '#868686',
    400: '#9B9B9B',
    300: '#B0B0B0',
    200: '#C6C6C6',
    100: '#DDDDDD',
    50: '#F3F3F3'
  }
};
