import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Container, InputAdornment, Link, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import BaseButton from 'components/formControl/baseButton/BaseButton';
import BaseTextField from 'components/formControl/baseTextField/BaseTextField';
import { ReactComponent as IconAtSign } from 'assets/icons/Icon-At-Sign.svg';
import { ReactComponent as IconCheckmark } from 'assets/icons/Icon-Checkmark.svg';

import { setStateValue } from 'store/reducers/user';

import palette from 'theme/palette';
import styles from './forgotPassword.module.scss';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const initialState = {
    email: ''
  };

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(t('validation.email')).required(t('validation.required'))
  });

  const handleSubmit = async (values) => {
    dispatch(setStateValue({ type: 'email', data: values.email }));
    navigate('/forgot-password/reset');
  };

  return (
    <Container component="main" maxWidth="xs" style={{ paddingBottom: '40px', marginTop: '115px' }}>
      <Formik
        initialValues={initialState}
        validationSchema={forgotPasswordSchema}
        onSubmit={handleSubmit}>
        {({ values, errors, handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <Box
              className={styles.formContainer}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <Typography component="h2" variant="h3" sx={{ marginBottom: '16px' }}>
                {t('forgotPassword')}
              </Typography>

              <Box component="div" sx={{ width: '100%' }}>
                <BaseTextField
                  InputLabelProps={{ required: false }}
                  sx={{ marginBottom: '40px' }}
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  helperText={errors.email}
                  error={!!errors.email}
                  value={values.email}
                  onChange={handleChange}
                  color={!errors.email && values.email ? 'success' : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconAtSign />
                      </InputAdornment>
                    ),
                    endAdornment:
                      !errors.email && values.email ? (
                        <InputAdornment position="end">
                          <IconCheckmark />
                        </InputAdornment>
                      ) : (
                        <div />
                      )
                  }}
                />
                <BaseButton
                  customColor={palette.variables.darkPurple}
                  type="submit"
                  fullWidth
                  variant="contained"
                  element={t('confirm')}
                  sx={{ marginBottom: '20px' }}
                />
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <Typography
                      style={{ marginRight: '5px' }}
                      color={palette.variables.greyLight}
                      variant="caption">
                      {t('rememberPassword')}
                    </Typography>
                    <Link
                      sx={{ textDecoration: 'none' }}
                      onClick={() => navigate('/signIn')}
                      variant="caption"
                      color="common.lightGreen">
                      {t('logIn')}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Container>
  );
}
