import React from 'react';
import { Box, Typography } from '@mui/material';

import styles from './admin.module.scss';

const ResourcesAdminPage = () => {
  return (
    <Box className={styles.container}>
      <Typography>ADMIN ResourcesAdminPage</Typography>
    </Box>
  );
};

export default ResourcesAdminPage;
