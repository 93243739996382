import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './forgotPasswordLayout.module.scss';
import HeaderWithLogoAndSwitcher from '../../views/Header/HeaderWithLogoAndSwitcher';
import { ReactComponent as CarretLeft } from '../../assets/icons/carret-left.svg';

export default function ForgotPasswordLayout() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer} dir="ltr">
        <CarretLeft onClick={() => navigate(-1)} className={styles.carretLeft} />
        <HeaderWithLogoAndSwitcher />
      </div>
      <Outlet />
    </div>
  );
}
