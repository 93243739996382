import { hasOwnProperty, isEqual } from 'lodash';

export const getObjectDiff = function getObjectDiff(obj1, obj2) {
  return Object.keys(obj1).reduce((result, key) => {
    if (!hasOwnProperty.call(obj2, key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));
};
