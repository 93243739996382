import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import BaseButton from 'components/formControl/baseButton/BaseButton';
import BaseTextarea from 'components/formControl/baseTextarea/BaseTextarea';
import BaseTextField from 'components/formControl/baseTextField/BaseTextField';
import ErrorNotification from 'components/ErrorNotification';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { setStateProductValue } from 'store/reducers/products';

import palette from 'theme/palette';
// import styles from '../admin.module.scss';

const ProductCategoryDetailsAdminPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const { selectedCategory, categories } = useSelector((state) => state.products);

  useEffect(() => {
    if (+id) {
      // get product category by id
    }
  }, []);

  const initialState = {
    name: selectedCategory.name || '',
    logo: selectedCategory.icon || '',
    name_1: '',
    description_1: '',
    name_2: '',
    description_2: '',
    name_3: '',
    description_3: '',
    name_4: '',
    description_4: '',
    name_5: '',
    description_5: '',
    name_6: '',
    description_6: ''
  };

  const ProductCategoryCreateSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    logo: Yup.string().required(t('validation.required'))
  });

  const handleSubmit = (values) => {
    const category = {
      id: categories.length,
      name: values.name,
      icon: values.logo
    };
    dispatch(setStateProductValue({ type: 'selectedCategory', data: category }));
    dispatch(setStateProductValue({ type: 'categories', data: [...categories, category] }));
    navigate(`/admin/product-category/${categories.length}`);
  };

  return (
    <Grid item md={6} xs={12} p={3} sx={{ maxWidth: 900, margin: '0 auto' }}>
      <Formik
        initialValues={initialState}
        validationSchema={ProductCategoryCreateSchema}
        onSubmit={handleSubmit}>
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h3" mb={3}>
                {id !== '0' && selectedCategory?.name
                  ? selectedCategory.name
                  : t('admin.addNewProductCategory')}
              </Typography>

              {id !== '0' && (
                <Box sx={{ display: 'flex' }}>
                  <Box mr={1}>
                    <EditIcon color="secondary" />
                  </Box>
                  <Box>
                    <DeleteForeverIcon color="error" />
                  </Box>
                </Box>
              )}
            </Box>

            {!errors && <ErrorNotification sx={{ marginBottom: '19px' }} errorText={'errors'} />}

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="name"
              label="Name"
              name="name"
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              value={values.name}
              color={!errors.name && values.name ? 'success' : ''}
            />

            <BaseTextField
              InputLabelProps={{ required: false }}
              margin="normal"
              fullWidth
              name="logo"
              label="Logo"
              id="logo"
              type="file"
              error={!!errors.logo}
              helperText={errors.logo}
              onChange={handleChange}
              value={values.logo}
              color={!errors.logo && values.logo ? 'success' : ''}
              sx={{ marginBottom: '30px' }}
            />

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="name_1"
              label="Field 1 Name"
              name="name_1"
              onChange={handleChange}
              error={!!errors.name_1}
              helperText={errors.name_1}
              value={values.name_1}
              color={!errors.name_1 && values.name_1 ? 'success' : ''}
            />

            <BaseTextarea
              style={{ marginBottom: '16px' }}
              color="common.greyLight"
              label="Field 1 Description"
              id="text"
              name="description_1"
              onChange={handleChange}
              error={!!errors.description_1}
              helperText={errors.description_1}
              value={values.description_1}
              placeholder="Add description"
            />

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="name_2"
              label="Field 2 Name"
              name="name_2"
              onChange={handleChange}
              error={!!errors.name_2}
              helperText={errors.name_2}
              value={values.name_2}
              color={!errors.name_2 && values.name_2 ? 'success' : ''}
            />

            <BaseTextarea
              style={{ marginBottom: '16px' }}
              color="common.greyLight"
              label="Field 2 Description"
              id="text"
              name="description_2"
              onChange={handleChange}
              error={!!errors.description_2}
              helperText={errors.description_2}
              value={values.description_2}
              placeholder="Add description"
            />

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="name_3"
              label="Field 3 Name"
              name="name_3"
              onChange={handleChange}
              error={!!errors.name_3}
              helperText={errors.name_3}
              value={values.name_3}
              color={!errors.name_3 && values.name_3 ? 'success' : ''}
            />

            <BaseTextarea
              style={{ marginBottom: '16px' }}
              color="common.greyLight"
              label="Field 3 Description"
              id="text"
              name="description_3"
              onChange={handleChange}
              error={!!errors.description_3}
              helperText={errors.description_3}
              value={values.description_3}
              placeholder="Add description"
            />

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="name_4"
              label="Field 4 Name"
              name="name_4"
              onChange={handleChange}
              error={!!errors.name_4}
              helperText={errors.name_4}
              value={values.name_4}
              color={!errors.name_4 && values.name_4 ? 'success' : ''}
            />

            <BaseTextarea
              style={{ marginBottom: '16px' }}
              color="common.greyLight"
              label="Field 4 Description"
              id="text"
              name="description_4"
              onChange={handleChange}
              error={!!errors.description_4}
              helperText={errors.description_4}
              value={values.description_4}
              placeholder="Add description"
            />

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="name_5"
              label="Field 5 Name"
              name="name_5"
              onChange={handleChange}
              error={!!errors.name_5}
              helperText={errors.name_5}
              value={values.name_5}
              color={!errors.name_5 && values.name_5 ? 'success' : 'common.greyLight'}
            />

            <BaseTextarea
              style={{ marginBottom: '16px' }}
              color="common.greyLight"
              label="Field 5 Description"
              id="text"
              name="description_5"
              onChange={handleChange}
              error={!!errors.description_5}
              helperText={errors.description_5}
              value={values.description_5}
              placeholder="Add description"
            />

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="name_6"
              label="Field 6 Name"
              name="name_6"
              onChange={handleChange}
              error={!!errors.name_6}
              helperText={errors.name_6}
              value={values.name_6}
              color={!errors.name_6 && values.name_6 ? 'success' : ''}
            />

            <BaseTextarea
              style={{ marginBottom: '16px' }}
              color="common.greyLight"
              label="Field 6 Description"
              id="text"
              name="description_6"
              onChange={handleChange}
              error={!!errors.description_6}
              helperText={errors.description_6}
              value={values.description_6}
              placeholder="Add description"
            />

            <BaseButton
              customColor={palette.variables.darkPurple}
              type="submit"
              fullWidth
              variant="contained"
              element={t('save')}
              sx={{ display: 'block', maxWidth: 300, marginTop: 5 }}
            />
          </form>
        )}
      </Formik>
    </Grid>
  );
};

export default ProductCategoryDetailsAdminPage;
