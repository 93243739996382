import React from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

const GuardedLayout = () => {
  return <Outlet />;
};

GuardedLayout.propTypes = {
  component: PropTypes.func
};

export default GuardedLayout;
