import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';

import ItemDashboard from 'views/Admin/Dashboard/ItemDashboard';
import BaseButton from 'components/formControl/baseButton/BaseButton';

import { filterList } from 'utils/fakeValues';

import palette from 'theme/palette';
import styles from '../admin.module.scss';

const FilterPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // get all filters
  }, []);

  const onCreateFilter = () => {
    navigate('/admin/filter/0');
  };

  return (
    <Box className={styles.container}>
      <Grid container alignItems="center" justifyContent="space-between" mb={3}>
        <Grid item>
          <Typography variant="h3" mt={3}>
            {t('admin.filters')}
          </Typography>
        </Grid>

        <Grid item sx={{ display: 'flex', alignItems: 'end' }}>
          <BaseButton
            customColor={palette.variables.orange}
            variant="contained"
            element={t('add')}
            sx={{ display: 'block', maxWidth: 300, height: 'max-content', marginLeft: 3 }}
            onClick={onCreateFilter}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="stretch">
        {filterList?.map((item) => (
          <Grid item md={4} xs={6} key={item.id}>
            <ItemDashboard item={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FilterPage;
