import React from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TabPanel from '../../views/Calculator/TabPanel';

import styles from './calculator.module.scss';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const CalculatorPage = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={styles.container}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          indicatorColor="secondary"
          textColor="primary"
          variant="fullWidth">
          <Tab
            label={
              <Typography variant="bodyBig" fontSize={16}>
                {t('calculator.homeLoan')}
              </Typography>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Typography variant="bodyBig" fontSize={16}>
                {t('calculator.personalLoan')}
              </Typography>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Typography variant="bodyBig" fontSize={16}>
                {t('calculator.autoLoan')}
              </Typography>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel
        value={value}
        index={0}
        title={`${t('calculator.homeLoan')} ${t('calculator.amount')}`}
      />
      <TabPanel
        value={value}
        index={1}
        title={`${t('calculator.personalLoan')} ${t('calculator.amount')}`}
      />
      <TabPanel
        value={value}
        index={2}
        title={`${t('calculator.autoLoan')} ${t('calculator.amount')}`}
      />
    </Box>
  );
};

export default CalculatorPage;
