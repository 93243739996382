import React from 'react';
import classnames from 'classnames';
import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';

import styles from './signUpLayout.module.scss';
import HeaderWithLogoAndSwitcher from 'views/Header/HeaderWithLogoAndSwitcher';

export default function SignUpLayout() {
  const [y, setY] = React.useState(window.scrollY);

  React.useEffect(() => {
    setY(window.scrollY);
  }, []);

  const scrollHandler = () => {
    setY(window.scrollY);
  };
  React.useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [y]);

  return (
    <div className={styles.container}>
      <div dir="ltr" className={classnames([styles.logoContainer, { [styles.scrolled]: y }])}>
        <HeaderWithLogoAndSwitcher />
      </div>
      <Container
        sx={{ margin: '90px auto 0 auto', paddingBottom: '45px' }}
        component="main"
        maxWidth="xs">
        <Outlet />
      </Container>
    </div>
  );
}
