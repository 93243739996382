import { createSlice } from '@reduxjs/toolkit';
import { getResourceById, getResources } from 'store/actions/resources';
const initialState = {
  resources: [],
  resource: null,
  selectedResource: null,
  selectedExpert: null,
  loading: false,
  error: null
};

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data
      };
    }
  },
  extraReducers: {
    [getResources.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getResources.fulfilled]: (state, { payload }) => {
      state.resources = payload;
      state.loading = false;
    },
    [getResources.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getResourceById.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getResourceById.fulfilled]: (state, { payload }) => {
      state.resource = payload;
      state.loading = false;
    },
    [getResourceById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export const { setStateValue } = resourcesSlice.actions;

export default resourcesSlice.reducer;
