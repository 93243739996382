import { API } from 'aws-amplify';

import RequestService from './RequestService';

const apiName = 'MyAPIGatewayAPI';
const path = '/content/product';

class ProductService extends RequestService {
  constructor(apiName, path, API) {
    super(apiName, path, API);
  }

  async searchProduct({ params }) {
    console.log(params);
    try {
      return await this._httpService.post(this._apiName, `${this._path}/search/`, {
        body: params
      });
    } catch (e) {
      throw new Error(e);
    }
  }
}

export default new ProductService(apiName, path, API);
