import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { ReactComponent as Wealth } from 'assets/icons/Wealth.svg';

// import palette from 'theme/palette';
import styles from '../Admin.module.scss';

const ItemDashboard = ({ item, onClick }) => {
  return (
    <Card onClick={onClick} className={styles.card}>
      <CardContent sx={{ padding: '16px 15px 8px' }}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} mb={2}>
            {item.icon ? (
              <img
                style={{ width: '80px', height: '54px', marginRight: '18px' }}
                src={item.icon}
                alt=""
              />
            ) : (
              <Wealth />
            )}
          </Grid>

          <Grid item xs={12} mb={2}>
            <Typography
              component="p"
              variant="bodyBig"
              color="common.darkPurple"
              textAlign="center">
              {item.name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ItemDashboard;

ItemDashboard.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func
};
