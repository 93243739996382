import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, Typography, InputAdornment, Link } from '@mui/material';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import * as Yup from 'yup';

import AppleSign from 'components/AppleSign';
import GoogleSign from 'components/GoogleSign';
import PageLoading from 'components/PageLoading';
import BaseButton from 'components/formControl/baseButton/BaseButton';
import ValidationCheckbox from 'components/formControl/ValidationCheckbox';
import BaseTextField from 'components/formControl/baseTextField/BaseTextField';
import ErrorNotification from 'components/ErrorNotification';
import { ReactComponent as IconAtSign } from 'assets/icons/Icon-At-Sign.svg';
import { ReactComponent as IconCheckmark } from 'assets/icons/Icon-Checkmark.svg';
import { ReactComponent as IconLock } from 'assets/icons/Icon-Lock.svg';
import { ReactComponent as IconHide } from 'assets/icons/Icon-hide.svg';
import { ReactComponent as IconShow } from 'assets/icons/Icon-show.svg';

import { setStateValue } from 'store/reducers/user';
import { signUp } from 'store/actions/user';

import palette from 'theme/palette';
import styles from './signUp.module.scss';

const initialState = {
  email: '',
  password: '',
  passwordRepeat: '',
  checkbox: false
};

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user, loading, error } = useSelector((state) => state.user);

  const [passwordType, setPasswordType] = useState('password');
  const [passwordRepeatType, setPasswordRepeatType] = useState('password');

  useEffect(() => {
    dispatch(setStateValue({ type: 'error', data: '' }));
    // if (!!user && Object.keys(user).length) {
    //   navigate('/verification');
    // }
  }, [user]);

  const SignUpSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('validation.required'))
      .test('is-password', ' ', (value) => {
        if (
          // /[a-z]/.test(value) &&
          // /[A-Z]/.test(value) &&
          // value.length > 8 &&
          // /[@$!%*#?&]/.test(value)
          /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).+$/.test(value) &&
          value.length > 8 &&
          value.length < 128
        ) {
          return true;
        }
        return false;
      }),
    passwordRepeat: Yup.string()
      .required(t('validation.required'))
      .test('is-mismatch-password', t('validation.passwordMismatch'), (value, { parent }) => {
        return parent.password === value;
      }),
    email: Yup.string().email(t('validation.email')).required(t('validation.required')),
    checkbox: Yup.bool().oneOf([true], t('validation.fieldMustBeChecked'))
  });

  const handleSubmit = (values) => {
    dispatch(signUp(values));
    dispatch(setStateValue({ type: 'tempPassword', data: values.password }));
  };

  return (
    <Formik
      initialValues={initialState}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={SignUpSchema}
      onSubmit={handleSubmit}>
      {({ values, handleChange, handleSubmit, errors }) => (
        <form onSubmit={handleSubmit}>
          <Box
            className={styles.formContainer}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <Typography component="h2" variant="h3" sx={{ marginBottom: '16px' }}>
              {t('register')}
            </Typography>

            <Typography
              component="h3"
              variant="body2"
              sx={{ marginBottom: '15px' }}
              color="common.grey">
              {t('createAccount')}
            </Typography>

            <Grid container justifyContent="center" spacing={3} sx={{ marginBottom: '24px' }}>
              <Grid item>
                <AppleSign
                  onClick={() =>
                    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple })
                  }
                />
              </Grid>

              <Grid item>
                <GoogleSign
                  onClick={() =>
                    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
                  }
                />
              </Grid>
            </Grid>

            <Typography
              component="h3"
              variant="body2"
              sx={{ marginBottom: '15px' }}
              color={palette.white['400']}>
              {t('orRegisterWith')}
            </Typography>

            {error ? (
              <ErrorNotification sx={{ marginBottom: '19px' }} errorText={error} />
            ) : errors.password === ' ' ? (
              <ErrorNotification
                errorText={t('validation.invalidPassword')}
                sx={{
                  marginBottom: '19px',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}>
                <Box sx={{ marginTop: '11px' }}>
                  <Typography
                    color="common.error"
                    sx={{ margin: 0, whiteSpace: 'break-spaces', textAlign: 'left' }}
                    variant="caption"
                    align="center"
                    display="block"
                    gutterBottom>
                    {t('validation.passwordRules')}
                  </Typography>
                </Box>
              </ErrorNotification>
            ) : (
              <div />
            )}

            <Box component="div" sx={{ width: '100%' }}>
              <BaseTextField
                InputLabelProps={{ required: false }}
                sx={{ marginBottom: '16px' }}
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                value={values.email}
                color={!errors.email && values.email ? 'success' : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconAtSign />
                    </InputAdornment>
                  ),
                  endAdornment:
                    !errors.email && values.email ? (
                      <InputAdornment position="end">
                        <IconCheckmark />
                      </InputAdornment>
                    ) : (
                      <div />
                    )
                }}
              />

              <BaseTextField
                InputLabelProps={{ required: false }}
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type={passwordType}
                id="password"
                error={!!errors.password}
                helperText={errors.password}
                onChange={handleChange}
                value={values.password}
                color={!errors.password && values.password ? 'success' : ''}
                sx={{ marginBottom: '4px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconLock />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() =>
                        setPasswordType(passwordType === 'text' ? 'password' : 'text')
                      }>
                      {passwordType === 'text' ? <IconShow /> : <IconHide />}
                    </InputAdornment>
                  )
                }}
              />

              <Typography
                style={{ marginBottom: '4px' }}
                color={palette.variables.greyLight}
                variant="caption"
                component="h3">
                At least 8 characters, at least 3 of the following: uppercase, lowercase, numeric,
                or special characters.
              </Typography>

              <BaseTextField
                InputLabelProps={{ required: false }}
                margin="normal"
                fullWidth
                name="passwordRepeat"
                label="Password Repeat"
                type={passwordRepeatType}
                id="passwordRepeat"
                className="password-type"
                error={!!errors.passwordRepeat}
                helperText={errors.passwordRepeat}
                onChange={handleChange}
                value={values.passwordRepeat}
                color={!errors.passwordRepeat && values.passwordRepeat ? 'success' : ''}
                sx={{ marginBottom: '24px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconLock />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() =>
                        setPasswordRepeatType(passwordRepeatType === 'text' ? 'password' : 'text')
                      }>
                      {passwordRepeatType === 'text' ? <IconShow /> : <IconHide />}
                    </InputAdornment>
                  )
                }}
              />

              <ValidationCheckbox
                margin="normal"
                name="checkbox"
                label={
                  <Typography component="h3" variant="caption" color={palette.white['400']}>
                    By creating an account, I agree to
                    <Typography
                      color={palette.variables.green}
                      sx={{ marginLeft: '5px' }}
                      component="span"
                      variant="caption">
                      Term and Conditions & Privacy Policy
                    </Typography>
                  </Typography>
                }
                id="checkbox"
                error={!!errors.checkbox}
                textHelper={errors.checkbox}
                onChange={handleChange}
                value={values.checkbox}
              />

              <BaseButton
                customColor={palette.variables.darkPurple}
                type="submit"
                fullWidth
                variant="contained"
                element={t('register')}
                sx={{ marginBottom: '30px', marginTop: '38px' }}
              />

              <Grid container alignItems="center" justifyContent="center">
                <Grid item alignItems="center">
                  <Typography
                    sx={{ marginBottom: 0, marginRight: '5px' }}
                    color={palette.white['400']}
                    variant="caption"
                    align="center"
                    display="block"
                    gutterBottom>
                    {t('alreadyHavaAccount')}
                  </Typography>
                </Grid>

                <Grid item>
                  <Link
                    onClick={() => navigate('/signIn')}
                    variant="body2"
                    color={palette.variables.green}>
                    {t('logIn')}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <PageLoading loading={loading} />
        </form>
      )}
    </Formik>
  );
}
