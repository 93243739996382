import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    region: process.env.REACT_APP_AMPLIFY_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AMPLIFY_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'http://localhost',
      redirectSignOut: process.env.REACT_APP_AMPLIFY_REDIRECT_SIGN_UP,
      responseType: 'code'
    }
  },
  API: {
    endpoints: [
      {
        name: 'MyAPIGatewayAPI',
        endpoint: process.env.REACT_APP_API_GATE_WAY,
        region: process.env.REACT_APP_AMPLIFY_REGION,
        scope: 'phone, email, profile, openid, aws.cognito.signin.user.admin',
        custom_header: async () => {
          return {
            authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          };
        }
      }
    ]
  }
});
