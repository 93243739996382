import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import VerifyCode from 'views/VerifyCode';

import { resendConfirmationCode, confirmSignUp } from 'store/actions/user';
import { setStateValue } from 'store/reducers/user';

const Verification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user, email, error, loading, deadlineStart, isRegistered } = useSelector(
    (state) => state.user
  );

  const [code, setCode] = useState({});

  useEffect(() => {
    isRegistered && navigate('/verification/success');
  }, [isRegistered]);

  const onVerify = () => {
    dispatch(confirmSignUp({ email: email || user?.username, code: Object.values(code).join('') }));
  };

  const onCodeChange = (value) => {
    setCode(value);
  };

  const onResend = async () => {
    if (!deadlineStart) {
      dispatch(setStateValue({ type: 'deadlineStart', data: true }));
      dispatch(resendConfirmationCode(email));
    }
  };

  return (
    <VerifyCode
      onInput={onCodeChange}
      title={t('verificationCode')}
      subTitle={`${t('verificationCodeSent')} ${email}`}
      buttonText={t('verify')}
      buttonSx={{ marginTop: '40px' }}
      onVerify={onVerify}
      error={!!error}
      onResend={onResend}
      buttonLoading={loading}
    />
  );
};
export default Verification;
