import { createAsyncThunk } from '@reduxjs/toolkit';
import ProductProvider from 'services/ProductProviderService';
import ProductCategory from 'services/ProductCategoryService';
import Product from 'services/ProductService';
import { saveFilters, setSearchProduct } from 'store/reducers/products';

export const fetchProvider = createAsyncThunk(
  'products/fetchProvider',
  async (params, thunkAPI) => {
    try {
      const response = await ProductProvider.getDataList({ params });
      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
export const fetchCategory = createAsyncThunk(
  'products/fetchCategory',
  async (params, thunkAPI) => {
    try {
      const response = await ProductCategory.getDataList({ params });
      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async (params, thunkAPI) => {
    try {
      const response = await Product.getDataList({ params });
      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const setSearchFilter = createAsyncThunk(
  'products/setSearchFilter',
  async (params, thunkAPI) => {
    try {
      await thunkAPI.dispatch(setSearchProduct(params));
      await thunkAPI.dispatch(searchProduct());
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const setFilters = createAsyncThunk('products/setSearchFilter', async (params, thunkAPI) => {
  try {
    await thunkAPI.dispatch(saveFilters(params));
    await thunkAPI.dispatch(searchProduct());
  } catch (err) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const searchProduct = createAsyncThunk(
  'products/searchProduct',
  async (params = {}, thunkAPI) => {
    try {
      const { products } = thunkAPI.getState();
      const answers = products.filters
        .filter((f) => Array.isArray(f.tag))
        .reduce((acc, curr) => {
          // acc.push(...curr.tag.map((t) => t.answer));
          acc.push(...curr.tag);
          return acc;
        }, []);

      const filters = {
        name: products.search,
        providerId: products.productProvider[0].id,
        categoryId: products.selectedCategory.id,
        // filterTags: [...products.filters.map((f) => f.tag.id).filter((f) => f), ...answers],
        filterTags: products.filters.map((f) => f.tag?.id).filter((f) => f),
        answers: answers,
        tags: [products.selectedTag.id]
      };

      const response = await Product.searchProduct({ params: { ...params, ...filters } });

      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const fetchProductById = createAsyncThunk(
  'products/fetchProductById',
  async (params, thunkAPI) => {
    try {
      const response = await Product.getDataById({ params });
      return response;
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
