import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import BaseTextField from 'components/formControl/baseTextField/BaseTextField';
import BaseTextarea from 'components/formControl/baseTextarea/BaseTextarea';
import BaseSelect from 'components/formControl/baseSelect/BaseSelect';
import BaseButton from 'components/formControl/baseButton/BaseButton';
import { ReactComponent as Wealth } from 'assets/icons/Wealth.svg';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

import { currencyOptions, tagsOptions } from 'utils/staticValues';

import palette from 'theme/palette';
import styles from '../admin.module.scss';

const ProductDetails = () => {
  // const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const { product } = useSelector((state) => state.products);

  const [tags, setTags] = useState(() => []);

  useEffect(() => {
    if (id === '0') {
      //get product details
    }
  }, []);

  const initialState = {
    name: '',
    imageThumb: '',
    imageFull: '',
    previewText: '',
    description: '',
    interestRate: '',
    annualFee: '',
    supplementaryCards: '',
    latePaymentFee: '',
    overlimitFee: '',
    temporaryLimitIncrease: '',
    aditional: '',
    tags: []
  };

  const ProductCreateSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    imageFull: Yup.string().required(t('validation.required'))
  });

  const handleSubmit = (values) => {
    console.log('values___', values);
    // dispatch(setStatePartnerValue({ type: 'partner', data: values }));
    // navigate('/admin/congrats');
  };

  return (
    <Box className={styles.cardProduct}>
      <Grid container>
        <Grid item>
          <Grid container>
            <Grid item>
              {product?.icon ? (
                <img
                  style={{ width: '80px', height: '54px', marginRight: '18px' }}
                  src={product.icon}
                  alt=""
                />
              ) : (
                <Box ml={1} mr={5}>
                  <Wealth />
                </Box>
              )}
            </Grid>

            <Grid item>
              <Typography variant="bodyBig" color="common.darkPurple">
                National Bank of Bahrain
              </Typography>
              <Typography color="common.darkPurple">Credit Card</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          {id !== '0' && (
            <Box sx={{ display: 'flex' }}>
              <Box mr={1}>
                <EditIcon color="secondary" />
              </Box>
              <Box>
                <DeleteForeverIcon color="error" />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      <Formik
        initialValues={initialState}
        validationSchema={ProductCreateSchema}
        onSubmit={handleSubmit}>
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="bodyBig" component="p" color="common.darkPurple" my={3}>
              {t('admin.productDetails')}
            </Typography>

            <BaseTextField
              InputLabelProps={{ required: false }}
              sx={{ marginBottom: '24px' }}
              margin="normal"
              fullWidth
              id="name"
              label="Name"
              name="name"
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              value={values.name}
              color={!errors.name && values.name ? 'success' : ''}
            />

            <BaseTextField
              InputLabelProps={{ required: false }}
              margin="normal"
              fullWidth
              name="imageThumb"
              label="Thumbnail Image"
              id="imageThumb"
              type="file"
              error={!!errors.imageThumb}
              helperText={errors.imageThumb}
              onChange={handleChange}
              value={values.imageThumb}
              color={!errors.imageThumb && values.imageThumb ? 'success' : ''}
              sx={{ marginBottom: '30px' }}
            />

            <BaseTextField
              InputLabelProps={{ required: false }}
              margin="normal"
              fullWidth
              name="imageFull"
              label="Full Size Image"
              id="imageFull"
              type="file"
              error={!!errors.imageFull}
              helperText={errors.imageFull}
              onChange={handleChange}
              value={values.imageFull}
              color={!errors.imageFull && values.imageFull ? 'success' : ''}
              sx={{ marginBottom: '30px' }}
            />

            <BaseTextarea
              style={{ marginBottom: '16px' }}
              color="common.greyLight"
              label="Preview Text"
              id="text"
              name="previewText"
              onChange={handleChange}
              error={!!errors.previewText}
              helperText={errors.previewText}
              value={values.previewText}
              placeholder="Add preview text"
            />

            <BaseTextarea
              style={{ marginBottom: '16px' }}
              color="common.greyLight"
              label="Product Description"
              id="text"
              name="description"
              onChange={handleChange}
              error={!!errors.description}
              helperText={errors.description}
              value={values.description}
              placeholder="Add description"
            />

            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={1} xs={12} alignItems="center">
                  <Grid item xs={8}>
                    <BaseTextField
                      InputLabelProps={{ required: false }}
                      sx={{ marginBottom: '24px' }}
                      margin="normal"
                      fullWidth
                      type="number"
                      id="interestRate"
                      label="Interest Rate"
                      name="interestRate"
                      onChange={handleChange}
                      error={!!errors.interestRate}
                      helperText={errors.interestRate}
                      value={values.interestRate}
                      color={!errors.interestRate && values.interestRate ? 'success' : ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BaseSelect
                      items={currencyOptions}
                      initvalue={1}
                      sx={{ minWidth: 50, marginBottom: 0 }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container spacing={1} xs={12} alignItems="center">
                  <Grid item xs={8}>
                    <BaseTextField
                      InputLabelProps={{ required: false }}
                      sx={{ marginBottom: '24px' }}
                      margin="normal"
                      fullWidth
                      type="number"
                      id="annualFee"
                      label="Annual Fee"
                      name="annualFee"
                      onChange={handleChange}
                      error={!!errors.annualFee}
                      helperText={errors.annualFee}
                      value={values.annualFee}
                      color={!errors.annualFee && values.annualFee ? 'success' : ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BaseSelect
                      items={currencyOptions}
                      initvalue={1}
                      sx={{ minWidth: 50, marginBottom: 0 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={1} xs={12} alignItems="center">
                  <Grid item xs={8}>
                    <BaseTextField
                      InputLabelProps={{ required: false }}
                      sx={{ marginBottom: '24px' }}
                      margin="normal"
                      fullWidth
                      type="number"
                      id="supplementaryCards"
                      label="Supplementary Cards"
                      name="supplementaryCards"
                      onChange={handleChange}
                      error={!!errors.supplementaryCards}
                      helperText={errors.supplementaryCards}
                      value={values.supplementaryCards}
                      color={
                        !errors.supplementaryCards && values.supplementaryCards ? 'success' : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BaseSelect
                      items={currencyOptions}
                      initvalue={1}
                      sx={{ minWidth: 50, marginBottom: 0 }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container spacing={1} xs={12} alignItems="center">
                  <Grid item xs={8}>
                    <BaseTextField
                      InputLabelProps={{ required: false }}
                      sx={{ marginBottom: '24px' }}
                      margin="normal"
                      fullWidth
                      type="number"
                      id="latePaymentFee"
                      label="Late payment fee"
                      name="latePaymentFee"
                      onChange={handleChange}
                      error={!!errors.latePaymentFee}
                      helperText={errors.latePaymentFee}
                      value={values.latePaymentFee}
                      color={!errors.latePaymentFee && values.latePaymentFee ? 'success' : ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BaseSelect
                      items={currencyOptions}
                      initvalue={1}
                      sx={{ minWidth: 50, marginBottom: 0 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/*  */}
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={1} xs={12} alignItems="center">
                  <Grid item xs={8}>
                    <BaseTextField
                      InputLabelProps={{ required: false }}
                      sx={{ marginBottom: '24px' }}
                      margin="normal"
                      fullWidth
                      type="number"
                      id="overlimitFee"
                      label="Overlimit fee"
                      name="overlimitFee"
                      onChange={handleChange}
                      error={!!errors.overlimitFee}
                      helperText={errors.overlimitFee}
                      value={values.overlimitFee}
                      color={!errors.overlimitFee && values.overlimitFee ? 'success' : ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BaseSelect
                      items={currencyOptions}
                      initvalue={1}
                      sx={{ minWidth: 50, marginBottom: 0 }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container spacing={1} xs={12} alignItems="center">
                  <Grid item xs={8}>
                    <BaseTextField
                      InputLabelProps={{ required: false }}
                      sx={{ marginBottom: '24px' }}
                      margin="normal"
                      fullWidth
                      type="number"
                      id="temporaryLimitIncrease"
                      label="Temporary limit increase"
                      name="temporaryLimitIncrease"
                      onChange={handleChange}
                      error={!!errors.temporaryLimitIncrease}
                      helperText={errors.temporaryLimitIncrease}
                      value={values.temporaryLimitIncrease}
                      color={
                        !errors.temporaryLimitIncrease && values.temporaryLimitIncrease
                          ? 'success'
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BaseSelect
                      items={currencyOptions}
                      initvalue={1}
                      sx={{ minWidth: 50, marginBottom: 0 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <BaseTextarea
              style={{ marginBottom: '16px' }}
              color="common.greyLight"
              label="Aditional details"
              id="text"
              name="aditional"
              onChange={handleChange}
              error={!!errors.aditional}
              helperText={errors.aditional}
              value={values.aditional}
              placeholder="Add description"
            />

            <ToggleButtonGroup
              sx={{ flexWrap: 'wrap', gap: '7px' }}
              color="primary"
              value={tags}
              onChange={(e, newValues) => {
                setTags(newValues);
                setFieldValue('tags', newValues);
              }}>
              {tagsOptions.map((product) => (
                <ToggleButton
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    overflow: 'auto',
                    marginBottom: '15px',
                    flexWrap: 'nowrap',
                    width: 'max-content'
                  }}
                  key={product.id}
                  value={product}>
                  {product.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            <BaseButton
              customColor={palette.variables.darkPurple}
              type="submit"
              fullWidth
              variant="contained"
              element={t('save')}
              sx={{ display: 'block', maxWidth: 300, marginTop: 5 }}
            />
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ProductDetails;
