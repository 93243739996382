import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import BaseButton from 'components/formControl/baseButton/BaseButton';
import BaseTextField from 'components/formControl/baseTextField/BaseTextField';
import ErrorNotification from 'components/ErrorNotification';

import { productsOptions } from 'utils/staticValues';
import { setStatePartnerValue } from 'store/reducers/partners';

import palette from 'theme/palette';
import styles from '../admin.module.scss';

const PartnerCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { partners } = useSelector((state) => state.partners);

  const [products, setProducts] = useState(() => []);

  useEffect(() => {
    // get error or success create partner
  }, []);
  
  const initialState = { name: '', logo: '', propucts: [] };

  const PartnerCreateSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    logo: Yup.string().required(t('validation.required'))
  });

  const handleSubmit = (values) => {
    const partner = {
      id: products.length,
      name: values.name,
      icon: '',
      products: values.products
    };
    dispatch(setStatePartnerValue({ type: 'partner', data: partner }));
    dispatch(setStatePartnerValue({ type: 'partners', data: [...partners, partner] }));
    navigate(`/admin/partners/details/${products.length}`);
  };


  return (
    <Box className={styles.cardPartner}>
      <Grid item md={6} xs={12} p={3}>
        <Formik
          initialValues={initialState}
          validationSchema={PartnerCreateSchema}
          onSubmit={handleSubmit}>
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Typography
                sx={{ marginBottom: '16px' }}
                component="h2"
                variant="h3"
                color={palette.variables.darkPurple}>
                {t('admin.addNewPartner')}
              </Typography>

              {!errors && <ErrorNotification sx={{ marginBottom: '19px' }} errorText={'errors'} />}

              <Box component="div">
                <BaseTextField
                  InputLabelProps={{ required: false }}
                  sx={{ marginBottom: '24px' }}
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  value={values.name}
                  color={!errors.name && values.name ? 'success' : ''}
                />

                <BaseTextField
                  InputLabelProps={{ required: false }}
                  margin="normal"
                  fullWidth
                  name="logo"
                  label="Logo"
                  id="logo"
                  type="file"
                  error={!!errors.logo}
                  helperText={errors.logo}
                  onChange={handleChange}
                  value={values.logo}
                  color={!errors.logo && values.logo ? 'success' : ''}
                  sx={{ marginBottom: '30px' }}
                />

                <Typography component="div" variant="bodyBig" color="common.darkPurple" mb={3}>
                  {t('admin.products')}
                </Typography>

                <ToggleButtonGroup
                  sx={{ flexWrap: 'wrap', gap: '7px' }}
                  color="primary"
                  value={products}
                  onChange={(e, newValues) => {
                    setProducts(newValues);
                    setFieldValue('products', newValues);
                  }}>
                  {productsOptions.map((product) => (
                    <ToggleButton
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        overflow: 'auto',
                        marginBottom: '15px',
                        flexWrap: 'nowrap',
                        width: 'max-content'
                      }}
                      key={product.id}
                      value={product}>
                      {product.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>

                <BaseButton
                  customColor={palette.variables.darkPurple}
                  type="submit"
                  fullWidth
                  variant="contained"
                  element={t('save')}
                  sx={{ display: 'block', maxWidth: 300, marginTop: 5 }}
                />
              </Box>
            </form>
          )}
        </Formik>
      </Grid>
    </Box>
  );
};

export default PartnerCreate;
