import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import BaseButton from 'components/formControl/baseButton/BaseButton';
import ItemDashboard from 'views/Admin/Dashboard/ItemDashboard';
import { ReactComponent as Trust } from 'assets/icons/Trust.svg';

import palette from 'theme/palette';
import styles from '../admin.module.scss';

const PartnerDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { partner } = useSelector((state) => state.partners);

  useEffect(() => {
    //get partner details
  }, []);

  const onCreateProduct = () => {
    navigate('/admin/products/tags/0');
  };

  const onGoToProduct = (item) => {
    navigate(`/admin/products/tags/${item.id}`);
  };

  return (
    <Box className={styles.cardPartner}>
      <Grid container alignItems="center" mb={5} mt={3}>
        <Grid item>
          {partner?.icon ? (
            <img
              style={{ width: '80px', height: '54px', marginRight: '18px' }}
              src={partner?.icon}
              alt=""
            />
          ) : (
            <Box ml={1} mr={5}>
              <Trust />
            </Box>
          )}
        </Grid>

        <Grid item>
          <Typography variant="bodyBig" color="common.darkPurple">
            {partner?.name}
          </Typography>
        </Grid>
      </Grid>

      <Grid container alignItems="center" mb={3}>
        <Grid item>
          <Typography variant="bodyBig" color="common.darkPurple">
            Products
          </Typography>
        </Grid>

        <Grid item>
          <BaseButton
            customColor={palette.variables.orange}
            variant="contained"
            element={t('add')}
            sx={{ display: 'block', maxWidth: 300, height: 'max-content', marginLeft: 3 }}
            onClick={onCreateProduct}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {partner?.products?.map((item) => (
          <Grid item md={4} xs={6} key={item.id}>
            <ItemDashboard item={item} onClick={() => onGoToProduct(item)} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PartnerDetails;

// PartnerCreate.propTypes = {
//   item: PropTypes.object,
//   onClick: PropTypes.func
// };
