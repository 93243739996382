export const filterAnswer = [
  { answer: 'Cashback' },
  { answer: 'Travel' },
  { answer: 'Low interest' },
  { answer: 'Reward' },
  { answer: 'Reward1' },
  { answer: 'Reward2' }
];

export const tabsHeaderPoints = [
  { id: 1, title: 'Finanical knowledge' },
  { id: 2, title: 'Physical activity' }
];

export const bannerPoints = [
  {
    id: 1,
    title: 'Do steps to earn points!',
    items: [
      {
        id: 1,
        title: '200K',
        text: 'Steps Walked',
        icon: require('assets/icons/points/FlagBanner.svg').default
      },
      {
        id: 2,
        title: '300',
        text: 'Points earned',
        icon: require('assets/icons/points/CircleWavyCheckOrng.svg').default
      }
    ]
  },
  {
    id: 2,
    title: 'Convert knowledge to points!',
    items: [
      {
        id: 1,
        title: '2 568',
        text: 'Completed quizes',
        icon: require('assets/icons/points/PersonSimpleRun.svg').default
      },
      {
        id: 2,
        title: '300',
        text: 'Points earned',
        icon: require('assets/icons/points/CircleWavyCheck.svg').default
      }
    ]
  }
];

export const pointsGraphics = [
  { name: 'M', uv: 100, pv: 2400, amt: 2400 },
  { name: 'T', uv: 400, pv: 2400, amt: 2400 },
  { name: 'W', uv: 200, pv: 2400, amt: 2400 },
  { name: 'T', uv: 300, pv: 2400, amt: 2400 },
  { name: 'F', uv: 400, pv: 2400, amt: 2400 },
  { name: 'S', uv: 350, pv: 2400, amt: 2400 },
  { name: 'S', uv: 150, pv: 2400, amt: 2400 }
];

export const sidebarList = [
  { id: 1, name: 'Dashboard', to: 'admin/dashboard', icon: '' },
  { id: 2, name: 'Settings', to: 'admin/settings', icon: '' },
  { id: 3, name: 'Activity', to: 'admin/activity', icon: '' }
];

export const dashboardList = [
  { id: 1, name: 'Partners', to: '/admin/partners', icon: '' },
  { id: 2, name: 'Products', to: '/admin/products', icon: '' },
  { id: 3, name: 'Product Category', to: '/admin/product-category', icon: '' },
  { id: 4, name: 'Experts', to: '/admin/experts', icon: '' },
  { id: 5, name: 'Resources', to: '/admin/resources', icon: '' },
  { id: 6, name: 'Quizzes', to: '/admin/quizzes', icon: '' },
  { id: 7, name: 'Tags', to: '/admin/tags', icon: '' },
  { id: 8, name: 'Users', to: '/admin/users', icon: '' },
  { id: 8, name: 'Filter', to: '/admin/filter/0', icon: '' }
];

export const productsOptions = [
  { id: 1, name: 'Credit Cards' },
  { id: 2, name: 'Personal Loans' },
  { id: 3, name: 'Auto Loans' },
  { id: 4, name: 'Savings' },
  { id: 5, name: 'Deposits' }
];

export const tagsOptions = [
  { id: 1, name: 'Rewards' },
  { id: 2, name: 'Travel' },
  { id: 3, name: 'Shariah compliant' },
  { id: 4, name: 'Cashback' },
  { id: 5, name: 'Low interest' }
];

export const currencyOptions = [
  { id: 1, title: 'BHD' },
  { id: 2, title: 'USD' },
  { id: 3, title: 'EU' }
];
